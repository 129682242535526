import React from 'react';
import { Route, IndexRedirect, Redirect, IndexRoute } from 'react-router';
import moment from 'moment';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { selectFeedType } from '@core/actions/space';
import { setFeatures } from '@core/actions/classRoom/featureFlags';
import { ANNOUNCEMENTS, COMMON_ROOM } from '@core/constants/postFeedType';
import { getCurrentSpaceId } from '@core/selectors/space';
import Paths from '@app/constants/paths';
import * as actionTypes from '@core/constants/actions';
import { currentUserId, getUserSpaceRole } from '@core/selectors/user';
import { fetchStartupData } from '@core/actions/startup';
import { numberOfSpacesSelector } from '@core/selectors/classroom';
import { institutionShortName } from '@core/selectors/institution';
import users from '@core/users';
import { registerLiveWithQueryClient } from '@core/actions/socket/events';
import { getCurrentUser } from '@core/utils/currentUser';
import fetchSignedCookie from '@core/utils/filesSignedCookie';
import { fetchFeatureFlags } from '@app/utils/featureFlags';
import fetchProviders from '@core/actions/lti/fetchProviders.operation';

import { getFirstMaterialId } from '@core/materials/selectors';
import goToMaterialsAction from '@app/actions/navigation/goToMaterials.action';
import UserBar from '../components/presentational/Navigation/UserBar';
import SpaceBar from '../components/presentational/Navigation/SpaceBar';
import * as navigationActions from '../actions/navigation';
import OverlayLoader from '../components/containers/OverlayLoader';
import UserProfile from '../components/presentational/UserProfile';
import InboxGroupsMenu from '../components/presentational/InboxV2';
import { getStore } from '../store';
import conversations from '../store/conversations';
import groups from '../store/groups';
import materials, { operations } from '../store/materials';
import notifications from '../store/notifications';
import reactions from '../store/reactions';
import assignments from '../store/assignments';
import { FETCH_SIGNED_FILE_COOKIE } from '../constants/actions';
import getSignedCookieTimestamp from '../selectors/signedCookie';
import { isNotificationsOpen } from '../selectors/routing';
import withSuspense from '../components/presentational/common/Suspense/Suspense';

const refreshCookieIntervalMS = 25 * 60 * 1000; // 25 minutes

const fetchFileCookie =
  ({ dispatch }) =>
  async () => {
    await fetchSignedCookie();
    dispatch({ type: FETCH_SIGNED_FILE_COOKIE });
  };

const refetchCookieIfNeeded =
  ({ getState, fetchCookie }) =>
  async () => {
    const timestamp = getSignedCookieTimestamp(getState());
    if (
      moment().diff(timestamp, 'ms') > refreshCookieIntervalMS ||
      !timestamp
    ) {
      await fetchCookie();
    }
  };

const fetchInitialData = async (queryClient) => {
  const store = getStore();
  const { data } = store.getState();
  if (!data.hasInitialData && !data.isFetchingInitialData) {
    const fetchCookie = fetchFileCookie({
      dispatch: store.dispatch,
    });

    await fetchCookie();
    setInterval(() => fetchCookie(), refreshCookieIntervalMS);

    const refetchCookie = refetchCookieIfNeeded({
      getState: store.getState,
      fetchCookie,
    });

    const live = {
      conversations: conversations.registerLive,
      groups: groups.registerLive,
      materials: materials.registerLive,
      notifications: notifications.registerLive,
      reactions: reactions.registerLive,
      assignments: assignments.registerLive,
      events: registerLiveWithQueryClient(queryClient),
      users: users.registerLive,
      onReconnect: refetchCookie,
    };

    store.dispatch(fetchProviders());

    return store.dispatch(
      fetchStartupData({
        includeClosedConversations: true,
        conversations,
        live,
        files: {},
        notifications: {
          shouldFetch: !isNotificationsOpen(store.getState()),
        },
      })
    );
  }
  return null;
};

const fetchAndSetFeatures = async (state, dispatch, ldClient, spaceId) => {
  const institutionName = institutionShortName(state);
  const userId = currentUserId(state);
  const spaceRole = getUserSpaceRole(state);
  const numberOfSpaces = numberOfSpacesSelector(state);

  const flags = await fetchFeatureFlags(
    ldClient,
    institutionName,
    spaceId,
    userId,
    spaceRole,
    numberOfSpaces
  );
  dispatch(setFeatures(flags));
};

const getOnEnter = (queryClient, ldClient) => async (nextState, replace) => {
  if (!getCurrentUser()) {
    replace({
      pathname: '/logout',
      state: { nextPathname: nextState.location.pathname },
    });
    return;
  }

  await fetchInitialData(queryClient);

  const { dispatch, getState } = getStore();
  const state = getState();

  dispatch(
    navigationActions.decideAndLoadClassRoom(
      nextState.params.classId,
      nextState.location.search,
      (spaceId) => fetchAndSetFeatures(state, dispatch, ldClient, spaceId)
    )
  );
};

const onEnterJourney = async (nextState) => {
  const { dispatch, getState } = getStore();
  const state = getState();
  const { classId } = nextState.params;
  const materialsArr = Object.values(state.data.materials);
  if (materialsArr.length === 0 || materialsArr[0].space !== classId) {
    dispatch(operations.fetchSections(classId));
  }
};

const onMaterialsEnter = async ({
  params: { sectionId, classId: spaceId },
}) => {
  if (!sectionId) {
    const { dispatch, getState } = getStore();

    const firstSectionId = getFirstMaterialId(getState());

    // Only redirect if we have a section, otherwise we'll get stuck in a loop
    if (firstSectionId) {
      dispatch(goToMaterialsAction(spaceId, firstSectionId));
    }
  }
};

const onEnterSpecificFeed = (feedType) => (nextState) => {
  const { dispatch } = getStore();
  dispatch(selectFeedType({ feedType, spaceId: nextState.params.classId }));
};

const onEnterGenericFeed = () => (nextState) => {
  const { dispatch, getState } = getStore();
  const storeState = getState();
  const currentSpaceId = getCurrentSpaceId(storeState);
  const nextSpaceId = nextState.params.classId;
  if (currentSpaceId !== nextSpaceId) {
    dispatch({
      type: actionTypes.SPACE_SELECT_FEED_TYPE,
      payload: { feedType: undefined },
    });
  }
};

const getOnNoSpacesEnter = (queryClient) => async (nextState, replace) => {
  if (!getCurrentUser()) {
    replace({
      pathname: '/logout',
      state: { nextPathname: nextState.location.pathname },
    });
    return;
  }
  await fetchInitialData(queryClient);
};

// lazy loaded components
const PostsTable = React.lazy(() =>
  import(
    '../components/presentational/ClassRoom/Feed/PostsTable/PostsTable.container'
  )
);
const PostView = React.lazy(() =>
  import('../components/presentational/ClassRoom/Feed/Post/Post.container')
);
const ImportantPosts = React.lazy(() =>
  import(
    '../components/presentational/ClassRoom/ImportantPosts/ImportantPosts.container'
  )
);
const ScheduledPosts = React.lazy(() =>
  import('../components/presentational/ClassRoom/Feed/ScheduledPosts')
);
const MaterialsTab = React.lazy(() =>
  import(
    '../components/presentational/ClassRoom/Material/MaterialsTab/MaterialsTab.container'
  )
);
const MaterialsOverview = React.lazy(() =>
  import(
    '../components/presentational/ClassRoom/Material/MaterialsOverview/MaterialsOverview.container'
  )
);
const Assignments = React.lazy(() =>
  import('../components/presentational/ClassRoom/Assignments')
);

const Submissions = React.lazy(() =>
  import('../components/presentational/ClassRoom/Submissions')
);
const People = React.lazy(() =>
  import('../components/presentational/ClassRoom/Settings/People')
);
const EducatorArea = React.lazy(() =>
  import('../components/presentational/ClassRoom/Material/EducatorArea')
);
const EngagementPage = React.lazy(() =>
  import('../components/presentational/ClassRoom/EngagementPage')
);
const OmniSearch = React.lazy(() =>
  import('../components/presentational/OmniSearch/OmniSearch.container')
);
const Journey = React.lazy(() =>
  import('../components/presentational/ClassRoom/Journey/Journey.container')
);
const Content = React.lazy(() =>
  import('../components/presentational/Content')
);
const MobileView = React.lazy(() =>
  import('../components/presentational/Mobile/MobileView')
);
const NoSpaces = React.lazy(() =>
  import('../components/presentational/NoSpaces')
);
const Quiz = React.lazy(() =>
  import(
    '../components/presentational/ClassRoom/Assignments/Quizzes/Quizz.container'
  )
);
const ImportFromMoodle = React.lazy(() =>
  import('../components/presentational/ClassRoom/ImportFromMoodle')
);

const Report = React.lazy(() =>
  import(
    '../components/presentational/ClassRoom/Assignments/Quizzes/Report.container'
  )
);
const ItemBank = React.lazy(() =>
  import(
    '../components/presentational/ClassRoom/Assignments/Quizzes/ItemBank.container'
  )
);

const DashboardRoute = (queryClient, ldClient) => {
  const onEnter = getOnEnter(queryClient, ldClient);
  const onNoSpacesEnter = getOnNoSpacesEnter(queryClient);
  const { webAppFtLearnosityQuizzes202402 } = useFlags();

  return (
    <Route path="">
      <Route path="dashboard" onEnter={onEnter} />
      <Route
        path="dashboard/:classId"
        components={{
          overlayLoader: OverlayLoader,
          content: withSuspense(Content),
          inboxGroupMenu: InboxGroupsMenu,
          omniSearch: withSuspense(OmniSearch),
          userBar: UserBar,
          spaceBar: SpaceBar,
          userProfile: UserProfile,
        }}
        onEnter={onEnter}
      >
        {/* legacy community path */}
        <Redirect from={Paths.FEED} to="community" />
        <Route path="post/:id" component={withSuspense(PostView)} />
        <Route path="important" component={withSuspense(ImportantPosts)} />
        <Route path="scheduled" component={withSuspense(ScheduledPosts)} />
        {/* legacy journey path */}
        <Route
          path="materials(/:sectionId)"
          component={withSuspense(MaterialsTab)}
        />

        <IndexRedirect to="community" />
        <Route path="community">
          <IndexRedirect to={Paths.FEED} />
          <Route
            path={Paths.ANNOUNCEMENTS}
            onEnter={onEnterSpecificFeed(ANNOUNCEMENTS)}
          >
            <IndexRoute component={withSuspense(PostsTable)} />
            <Route path="scheduled" component={withSuspense(ScheduledPosts)} />
          </Route>
          <Route
            path={Paths.COMMON_ROOM}
            onEnter={onEnterSpecificFeed(COMMON_ROOM)}
          >
            <IndexRoute component={withSuspense(PostsTable)} />
            <Route path="scheduled" component={withSuspense(ScheduledPosts)} />
          </Route>
          <Route
            path={Paths.FEED}
            onEnter={onEnterGenericFeed()}
            component={withSuspense(PostsTable)}
          />
          <Route path="post/:id" component={withSuspense(PostView)} />
          <Route path="important" component={withSuspense(ImportantPosts)} />
          <Redirect from="scheduled" to={`${Paths.COMMON_ROOM}/scheduled`} />
        </Route>
        {/* new journey path */}
        <Route
          path="journey"
          component={withSuspense(Journey)}
          onEnter={onEnterJourney}
        >
          <IndexRedirect to="materials" />
          <Route
            path="materials/overview"
            component={withSuspense(MaterialsOverview)}
          />
          <Route
            path="materials(/:sectionId)"
            onEnter={onMaterialsEnter}
            component={withSuspense(MaterialsTab)}
          />
          <Route path="assignments" component={withSuspense(Assignments)} />
          <Route
            path="assignments/:assignmentId/submissions"
            component={withSuspense(Submissions)}
          />

          {webAppFtLearnosityQuizzes202402 === 'enabled' ? (
            <>
              <Route
                path="quizzes/item-bank"
                component={withSuspense(ItemBank)}
              />
            </>
          ) : (
            <Redirect from="quizzes/item-bank" to="/" />
          )}
          <Route
            path="quizzes(/:assignmentId)(/:submissionId)"
            component={withSuspense(Quiz)}
          />
          <Route
            path="reports/:assignmentId/:submissionId/:reportType"
            component={withSuspense(Report)}
          />
          <Route
            path="educatorArea(/:sectionId)"
            component={withSuspense(EducatorArea)}
          />
          <Route path="engagement" component={withSuspense(EngagementPage)} />
        </Route>
        <Route
          path="import-from-moodle"
          exact
          component={withSuspense(ImportFromMoodle)}
        />
        <Route path="people" component={withSuspense(People)}>
          <Route path="participants" component={withSuspense(People)} />
          <Route path="groups(/:groupSetId)" component={withSuspense(People)} />
        </Route>
      </Route>
      <Route
        path="nospaces"
        components={{
          overlayLoader: OverlayLoader,
          content: () =>
            withSuspense(NoSpaces)({
              message: `You're all set up! But before you can really get started with Aula, admins at your institution will have to add you to your spaces. In the meantime, you can connect with others by messaging them on the right-hand side.`,
            }),
          inboxGroupMenu: InboxGroupsMenu,
          omniSearch: withSuspense(OmniSearch),
          userBar: UserBar,
          userProfile: UserProfile,
        }}
        onEnter={onNoSpacesEnter}
      />
      <Route
        path="nospaces-lti"
        components={{
          overlayLoader: OverlayLoader,
          content: () =>
            withSuspense(NoSpaces)({
              message: `You're all set up! Unfortunately, educators at your institution still have to finish setting up the space that you are trying to access. Make sure you come back later. In the meantime, you can connect with others by messaging them on the right-hand side.`,
            }),
          inboxGroupMenu: InboxGroupsMenu,
          omniSearch: withSuspense(OmniSearch),
          userBar: UserBar,
          userProfile: UserProfile,
        }}
        onEnter={onNoSpacesEnter}
      />
      <Route path="mobile" components={withSuspense(MobileView)} />
    </Route>
  );
};

export default DashboardRoute;
